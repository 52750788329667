import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { VERIFY_TOKEN } from './components/gql/Mutation'
import { useToken } from './context/TokenProvider'
import Admin from './pages/Admin'
import Analytics from './pages/Analytics'

import Login from './pages/Login'
import NotFound from './pages/NotFoud'
import Output from './pages/Output'
import Search from './pages/Search'
import Settings from './pages/Settings'
import UserManage from './pages/UserManage'

const App = () => {
	const { token, loadingAll } = useToken()

	if (!token) {
		return <Login />
	}

	return (
		<>
			{!loadingAll && (
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/search" element={<Search />} />
					<Route
						path="/analytics"
						element={
							<Admin>
								<Analytics />
							</Admin>
						}
					/>
					<Route
						path="/output"
						element={
							<Admin>
								<Output />
							</Admin>
						}
					/>
					<Route
						path="/user-manage"
						element={
							<Admin>
								<UserManage />
							</Admin>
						}
					/>
					<Route
						path="/settings"
						element={
							<Admin>
								<Settings />
							</Admin>
						}
					/>
					<Route path="*" element={<NotFound />} />
				</Routes>
			)}
		</>
	)
}
export default App
