import { useState } from 'react'
import SearchLoading from '../../components/search/SearchLoading'
import SearchResultList from '../../components/search/SearchResultList'
import SearchTextField from '../../components/search/SearchTextField'
import SidebarList from '../../components/sidebar/SidebarList'
import CheckedProvider from '../../context/CheckedProvider'

const Search = () => {
	const [searchWord, setSearchWord] = useState<string>('')

	const setter = (newValue: string) => {
		setSearchWord(newValue)
	}

	return (
		<CheckedProvider>
			<div className="search">
				<div className="search-container">
					<SearchTextField
						value={searchWord}
						onChange={setter}
						helper="地域名など取得したいキーワードを入力してください。"
						id="search"
					/>
					<SearchResultList searchWord={searchWord} />
				</div>
				<SidebarList />
			</div>
		</CheckedProvider>
	)
}

export default Search
