import {
	Box,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import React, { useState } from 'react'
import TableHeadCell from '../../types/TableHeadCell'
import { useQuery } from '@apollo/client'
import {
	GET_USERS_BY_COMPANY_BRANCH,
	GET_USERS_COUNT_BY_COMPANY_BRANCH,
} from '../gql/Queries'
import convertMySqlDate from '../../utils/convertMySqlDate'
import User from '../../types/User'
import getCapabilityLabel from '../../utils/getCapabilityLabel'
import { indigo } from '@mui/material/colors'
import ListPopover from '../popover/ListPopover'
import File from '../../types/File'
import Capability from '../../types/Capability'

const headCells: readonly TableHeadCell[] = [
	{
		id: 'name',
		label: '名前',
		align: 'left',
	},
	{
		id: 'manage',
		label: '権限',
		align: 'center',
		width: 180,
	},
	{
		id: 'download',
		label: 'ダウンロード回数',
		align: 'center',
		width: 180,
	},
	{
		id: 'file',
		label: `ファイル数`,
		align: 'center',
		width: 180,
	},
	{
		id: 'last-login',
		label: '最終ログイン',
		align: 'center',
		width: 180,
	},
]

const nowDate = new Date()
nowDate.setDate(1)
nowDate.setHours(0)
nowDate.setMinutes(0)
nowDate.setSeconds(0)

const AnalyticsTableList = ({
	companyId = '',
	branchId = '',
}: {
	branchId?: string
	companyId?: string
}) => {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState<number>(5)
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const [files, setFiles] = useState<File[]>([])
	const { loading, error, data } = useQuery(
		GET_USERS_BY_COMPANY_BRANCH({
			companyId: companyId,
			branchId: branchId,
			start: convertMySqlDate(nowDate),
			after: page * rowsPerPage,
			limit: rowsPerPage,
		})
	)
	const {
		loading: userCountLoading,
		error: userCountError,
		data: userCountData,
	} = useQuery(
		GET_USERS_COUNT_BY_COMPANY_BRANCH({
			companyId: companyId,
			branchId: branchId,
		})
	)

	const handlePopoverOpen = (
		event: React.MouseEvent<HTMLElement>,
		files: File[]
	) => {
		setAnchorEl(event.currentTarget)
		setFiles(files)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const emptyRows =
		page > 0 && !userCountLoading && !userCountError
			? Math.max(
					0,
					(1 + page) * rowsPerPage - userCountData.allUsers.length
			  )
			: 0

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								{headCells.map((headCell) => (
									<TableCell
										key={headCell.id}
										align={headCell.align}
										sx={{
											width: `${headCell.width}px`,
										}}
									>
										{headCell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{!loading && !error
								? data.users.map((row: User, index: number) => {
										const labelId = `enhanced-table-checkbox-${index}`

										return (
											<TableRow
												hover
												tabIndex={-1}
												key={row.id}
											>
												<TableCell
													component="th"
													id={labelId}
													scope="row"
													align="left"
												>
													{row.name}
												</TableCell>
												<TableCell align="center">
													{getCapabilityLabel(
														row.capability as Capability
													)}
												</TableCell>
												<TableCell align="center">
													{row.downloadZips?.length.toLocaleString()}
												</TableCell>
												<TableCell
													align="center"
													onClick={(e) =>
														handlePopoverOpen(
															e,
															row.downloadFiles as File[]
														)
													}
													style={{
														cursor: 'pointer',
													}}
												>
													{row.downloadFiles?.length.toLocaleString()}
												</TableCell>
												<TableCell align="center">
													{row.access?.loginDate}
												</TableCell>
											</TableRow>
										)
								  })
								: Array.from(Array(rowsPerPage).keys()).map(
										(num) => (
											<TableRow
												hover
												tabIndex={-1}
												key={num}
											>
												<TableCell
													component="th"
													scope="row"
													align="left"
												>
													<Skeleton
														variant="text"
														animation="wave"
														sx={{
															fontSize: '1rem',
															bgcolor: indigo[50],
															height: '20px',
															lineHeight: 1,
														}}
													/>
												</TableCell>
												<TableCell align="center">
													<Skeleton
														variant="text"
														animation="wave"
														sx={{
															fontSize: '1rem',
															bgcolor: indigo[50],
															height: '20px',
															lineHeight: 1,
														}}
													/>
												</TableCell>
												<TableCell align="center">
													<Skeleton
														variant="text"
														animation="wave"
														sx={{
															fontSize: '1rem',
															bgcolor: indigo[50],
															height: '20px',
															lineHeight: 1,
														}}
													/>
												</TableCell>
												<TableCell align="center">
													<Skeleton
														variant="text"
														animation="wave"
														sx={{
															fontSize: '1rem',
															bgcolor: indigo[50],
															height: '20px',
															lineHeight: 1,
														}}
													/>
												</TableCell>
												<TableCell align="center">
													<Skeleton
														variant="text"
														animation="wave"
														sx={{
															fontSize: '1rem',
															bgcolor: indigo[50],
															height: '20px',
															lineHeight: 1,
														}}
													/>
												</TableCell>
											</TableRow>
										)
								  )}
							{!loading && !error && emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={
						!userCountLoading && !userCountError
							? userCountData.allUsers.length
							: 0
					}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="表示数"
				/>
				<ListPopover
					files={files}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={handlePopoverClose}
				/>
			</Paper>
		</Box>
	)
}

export default AnalyticsTableList
