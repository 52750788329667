import { useLazyQuery, useMutation } from '@apollo/client'
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { VERIFY_TOKEN } from '../components/gql/Mutation'
import { GET_USER } from '../components/gql/Queries'
import User from '../types/User'

interface TokenContext {
	token: string
	setUserToken: (token: string) => void
	removeUserToken: () => void
	loading: boolean
	user: User | undefined
	userLoading: boolean
	loadingAll: boolean
	setRedirect: (flag: boolean) => void
}

const TokenContext = createContext<TokenContext>({
	token: '',
	setUserToken: () => {},
	removeUserToken: () => {},
	loading: false,
	user: undefined,
	userLoading: false,
	loadingAll: true,
	setRedirect: () => {},
})

export const useToken = () => useContext(TokenContext)

const TokenProvider = ({ children }: { children?: ReactNode }) => {
	const [token, setToken] = useState<string>(
		String(localStorage.getItem('token'))
	)
	const [redirect, setRedirect] = useState<boolean>(false)
	const [user, setUser] = useState<User>()
	const [verifyToken, { loading, error, data }] = useMutation(VERIFY_TOKEN)
	const [
		getUser,
		{ loading: userLoading, error: userError, data: userData },
	] = useLazyQuery(GET_USER)
	const navigate = useNavigate()
	const [loadingAll, setLoadingAll] = useState<boolean>(true)

	useEffect(() => {
		localStorage.setItem('token', token)
		if (token) {
			setLoadingAll(true)
			verifyToken({
				variables: {
					token: token,
				},
				onCompleted: (res) => {
					if (!res.verifyToken) {
						removeUserToken()
						setLoadingAll(false)
					} else {
						getUser({
							onCompleted: (userData) => {
								userData?.user
									? setUser(userData.user)
									: setUser(undefined)
								setLoadingAll(false)
								if (redirect) {
									userData.user.capability !== 'admin'
										? navigate('/search')
										: navigate('/analytics')
									setRedirect(false)
								}
							},
						})
					}
				},
				onError: () => {
					removeUserToken()
					setLoadingAll(false)
				},
			})
		}
	}, [token])

	const setUserToken = (t: string) => setToken(t)

	const removeUserToken = () => setToken('')

	return (
		<TokenContext.Provider
			value={{
				token,
				setUserToken,
				removeUserToken,
				loading,
				user,
				userLoading,
				loadingAll,
				setRedirect,
			}}
		>
			{children}
		</TokenContext.Provider>
	)
}

export default TokenProvider
