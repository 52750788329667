import {
	Box,
	Checkbox,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import { useChecked } from '../../context/CheckedProvider'
import './style.scss'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_FILES } from '../gql/Queries'
import File from '../../types/File'
import LoadingAnimation from '../loading/LoadingAnimation'

const SearchResultList = ({ searchWord = '' }: { searchWord?: string }) => {
	const {
		loading,
		error,
		data: fileData,
	} = useQuery(GET_FILES, {
		variables: {
			after: 0,
			limit: 100000,
		},
	})
	const [filteredData, setFilteredData] = useState<File[]>([])
	const { checked, updateChecked } = useChecked()
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(25)

	useEffect(() => {
		if (!loading && !error) {
			const regex = new RegExp(searchWord)
			setFilteredData(
				fileData.files
					.filter((file: File) => regex.test(file.filename as string))
					.sort(
						(a: File, b: File) =>
							a.filename?.localeCompare(b.filename as string),
						'ja'
					)
			)
		}
	}, [loading, searchWord])

	useEffect(() => {
		setPage(0)
	}, [searchWord])

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - filteredData.length)
			: 0

	return (
		<>
			{loading && <LoadingAnimation label="初回ファイル一覧読み込み中" />}
			{searchWord.length > 2 && (
				<Box sx={{ width: '100%', maxWidth: '600px', mt: '1rem' }}>
					<Paper sx={{ width: '100%', mb: 2 }}>
						<TableContainer>
							<Table aria-labelledby="tableTitle">
								<TableBody>
									{filteredData
										.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage
										)
										.map((row) => {
											const index =
												row.id !== undefined
													? parseInt(row.id)
													: 0
											const labelId = `search-result-list-${index}`
											const ischecked =
												checked.indexOf(index) !== -1
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={index}
													onClick={(e) =>
														updateChecked(index)
													}
													role="checkbox"
													aria-checked={ischecked}
													selected={ischecked}
												>
													<TableCell padding="checkbox">
														<Checkbox
															color="primary"
															checked={ischecked}
															inputProps={{
																'aria-labelledby':
																	labelId,
															}}
														/>
													</TableCell>
													<TableCell
														id={labelId}
														scope="row"
														align="left"
													>
														{row.filename}
													</TableCell>
												</TableRow>
											)
										})}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{filteredData.length > 0 && (
							<TablePagination
								rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
								component="div"
								count={filteredData.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage="表示数"
							/>
						)}
					</Paper>
				</Box>
			)}
		</>
	)
}

export default SearchResultList
