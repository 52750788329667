import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	SelectChangeEvent,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useEffect, useState } from 'react'
import AdminBase from '../../components/admin-base/AdminBase'
import { GET_COMPANIES_BRANCHES, GET_YEARS } from '../../components/gql/Queries'
import { useQuery } from '@apollo/client'
import CompanyBranch from '../../components/company-branch/CompanyBranch'
import Branch from '../../types/Branch'
import getBranches from '../../utils/getBranches'
import LoadingAnimation from '../../components/loading/LoadingAnimation'
import { useToken } from '../../context/TokenProvider'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Output = () => {
	const [years, setYears] = useState<number[]>([])
	const [year, setYear] = useState<number>(0)
	const [month, setMonth] = useState<number>(1)
	const [company, setCompany] = useState<string>('')
	const [branchList, setBranchList] = useState<Branch[]>([])
	const [open, setOpen] = useState(false)
	const [branch, setBranch] = useState<string>('')
	const { token } = useToken()
	const {
		loading: yearLoading,
		error: yearError,
		data: yearData,
	} = useQuery(GET_YEARS)
	const { loading, error, data } = useQuery(GET_COMPANIES_BRANCHES)
	const [yearSelectorError, setYearSelectorError] = useState<boolean>(false)
	const [monthError, setMonthError] = useState<boolean>(false)
	const [companyError, setCompanyError] = useState<boolean>(false)
	const [branchError, setBranchError] = useState<boolean>(false)
	const navigate = useNavigate()

	const handleChangeYear = (event: SelectChangeEvent) => {
		setYear(parseInt(event.target.value))
		if (event.target.value !== '') {
			setYearSelectorError(false)
		}
	}

	const handleChangeMonth = (event: SelectChangeEvent) => {
		setMonth(parseInt(event.target.value))
		if (event.target.value !== '') {
			setMonthError(false)
		}
	}

	const handleChangeCompany = (event: SelectChangeEvent) => {
		setCompany(event.target.value)
		if (event.target.value !== '') {
			setCompanyError(false)
		}
	}

	const handleChangeBranch = (event: SelectChangeEvent) => {
		setBranch(event.target.value)
		if (event.target.value !== '') {
			setBranchError(false)
		}
	}

	useEffect(() => {
		setBranchList(getBranches(loading, error, data, company))
		setBranch('')
	}, [company])

	useEffect(() => {
		if (!yearLoading && !yearError) {
			setYears(yearData.years)
		}
	}, [yearLoading])

	const checkInput = () => {
		let flag = false

		if (company === '') {
			setCompanyError(true)
			flag = true
		}

		if (branch === '') {
			setBranchError(true)
			flag = true
		}

		if (year === 0) {
			setYearSelectorError(true)
			flag = true
		}

		if (month === 0) {
			setMonthError(true)
			flag = true
		}

		return flag
	}

	const loadingEvent = () => {
		setOpen(true)

		const config = {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json;',
				'Access-Control-Allow-Origin': '*',
				authorization: `Bearer ${token}`,
			},
		}

		axios
			.post(
				// 'http://localhost:89/google-drive-data-system/download-pdf.php',
				// 'https://kuuuuuma.com/download-pdf.php',
				'https://grandcity.group/download-pdf.php',
				{
					year: year,
					month: month,
					branch: branch,
				},
				config
			)
			.then((response) => {
				if (response.data.status === 200) {
					const a = document.createElement('a')
					a.setAttribute(
						'href',
						'data:text/plain;base64,' + response.data.file
					)
					a.setAttribute(
						'download',
						`${year}-${month}-${
							branchList.find((b) => b.id === branch)?.name
						}.pdf`
					)

					a.style.display = 'none'
					document.body.appendChild(a)

					a.click()
					document.body.removeChild(a)
				} else if (response.data.status === 403) {
					alert(
						'セッションの有効期限が切れているため再度ログインしてください。'
					)
					navigate('/')
				}

				setOpen(false)
			})
			.catch((error) => {
				console.log(error)
				setOpen(false)
			})
	}

	const modalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 300,
		bgcolor: '#ffffffe0',
		borderRadius: '8px',
		p: 4,
	}

	return (
		<AdminBase title="出力">
			<Box sx={{ pl: '1rem', pt: 2 }}>
				<Grid2
					container
					spacing={2}
					sx={{ mb: 1, maxWidth: '100%', width: '100%' }}
				>
					<Grid2 xs={1.5}>
						<FormControl error={yearSelectorError}>
							<InputLabel id="demo-simple-select-label">
								年
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={String(year)}
								label="年"
								onChange={handleChangeYear}
								sx={{ minWidth: '150px' }}
							>
								{!yearLoading &&
									!yearError &&
									years.map((y) => (
										<MenuItem value={y} key={y}>
											{y}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid2>
					<Grid2 xs={3}>
						<FormControl error={monthError}>
							<InputLabel id="demo-simple-select-label">
								月
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={String(month)}
								label="月"
								onChange={handleChangeMonth}
								sx={{ minWidth: '150px' }}
							>
								{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
									(m) => {
										return (
											<MenuItem key={m} value={m}>
												{m}
											</MenuItem>
										)
									}
								)}
							</Select>
						</FormControl>
					</Grid2>
				</Grid2>
				<CompanyBranch
					company={company}
					branch={branch}
					branchList={branchList}
					companies={!loading && !error ? data.allCompany : []}
					loading={loading}
					error={error}
					onChangeCompany={handleChangeCompany}
					onChangeBranch={handleChangeBranch}
					companyError={companyError}
					branchError={branchError}
				/>
			</Box>
			<Button
				sx={{ width: '150px', ml: '1rem', mt: 2 }}
				onClick={(e) => {
					if (!checkInput()) {
						loadingEvent()
					}
				}}
				variant="contained"
			>
				ダウンロード
			</Button>
			<Modal
				open={open}
				onClose={() => {}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={modalStyle}>
					<LoadingAnimation label="ダウンロード中" />
				</Box>
			</Modal>
		</AdminBase>
	)
}

export default Output
