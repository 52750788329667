import { Box } from '@mui/material'
import { indigo } from '@mui/material/colors'
import { ReactNode } from 'react'
import SidebarMenu from '../sidebar/SidebarMenu'

import './style.scss'

const AdminBase = ({
	title,
	children,
}: {
	title: string
	children?: ReactNode
}) => {
	return (
		<Box
			className="admin-container"
			sx={{
				backgroundColor: indigo[50],
				minHeight: '100vh',
				maxWidth: '100vw',
				width: '100vw',
				overflow: 'hidden',
			}}
		>
			<SidebarMenu />
			<Box sx={{ pl: 'calc( 220px + 1rem )' }} className="admin-body">
				<Box className="admin-body__header" sx={{ p: '1rem' }}>
					<h1 className="admin-body__title">
						<span
							className="admin-body__title-text"
							style={{
								borderBottom: `2px dashed ${indigo[700]}`,
							}}
						>
							{title}
						</span>
					</h1>
				</Box>
				{children}
			</Box>
		</Box>
	)
}

export default AdminBase
