const convertMySqlDate = (date: Date) => {
	const Y = date.getFullYear();
	const m = zeroFill(date.getMonth() + 1);
	const d = zeroFill(date.getDate());
	const H = zeroFill(date.getHours());
	const i = zeroFill(date.getMinutes());
	const s = zeroFill(date.getSeconds());

	return `${Y}-${m}-${d} ${H}:${i}:${s}`
}

const zeroFill = ( num: number ) => {
	return ('00' + String(num)).slice(-2)
}

export default convertMySqlDate;
