import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	SelectChangeEvent,
	TextField,
	FormControl,
	FormLabel,
	InputLabel,
	Input,
	InputAdornment,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useEffect, useLayoutEffect, useReducer, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_COMPANIES_BRANCHES } from '../gql/Queries'
import Branch from '../../types/Branch'
import Company from '../../types/Company'
import getBranches from '../../utils/getBranches'
import User from '../../types/User'
import CompanyBranch from '../company-branch/CompanyBranch'
import UserChangeInput from '../../types/UserChangeInput'
import CapabilityRadio from '../capability-radio/CapabilityRadio'

const EditDialog = ({
	userInfo,
	open,
	onClose,
	onCancel,
	onSave,
}: {
	userInfo: User
	open: boolean
	onClose: () => void
	onCancel: () => void
	onSave: (input: UserChangeInput) => void
}) => {
	const [user, setUser] = useState<User>(userInfo)
	const [view, setView] = useReducer<(v: boolean) => boolean>(
		(view) => !view,
		false
	)
	const [company, setCompany] = useState<string>('')
	const [branchList, setBranchList] = useState<Branch[]>([])
	const [branch, setBranch] = useState<string>('')
	const [userType, setUserType] = useState<string>('')
	const [password, setPassWord] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [loginId, setLoginId] = useState<string>('')
	const { loading, error, data } = useQuery(GET_COMPANIES_BRANCHES)
	const [companyError, setCompanyError] = useState<boolean>(false)
	const [branchError, setBranchError] = useState<boolean>(false)
	const [nameError, setNameError] = useState<boolean>(false)
	const [passwordError, setPasswordError] = useState<boolean>(false)

	const handleChangeUserType = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setUserType(event.target.value)
	}

	const handleChangeCompany = (event: SelectChangeEvent) => {
		setCompany(event.target.value as string)
		if (event.target.value !== '') {
			setCompanyError(false)
		}
	}

	const handleChangeBranch = (event: SelectChangeEvent) => {
		setBranch(event.target.value as string)
		if (event.target.value !== '') {
			setBranchError(false)
		}
	}

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value)
		if (event.target.value !== '') {
			setNameError(false)
		}
	}

	const handleChangePassWord = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setPassWord(event.target.value)
		if (event.target.value.length >= 16) {
			setPasswordError(false)
		}
	}

	const checkInput = () => {
		let flag = false

		if (company === '') {
			setCompanyError(true)
			flag = true
		}

		if (branch === '') {
			setBranchError(true)
			flag = true
		}

		if (name === '') {
			setNameError(true)
			flag = true
		}

		if (password.length < 16 && password.length !== 0) {
			setPasswordError(true)
			flag = true
		}

		return flag
	}

	useLayoutEffect(() => {
		setUser(userInfo)
		if (userInfo !== undefined) {
			const companyId = String(userInfo.company?.id)
			setCompany(companyId)
			const firstBranchList = getBranches(loading, error, data, companyId)
			setBranchList(firstBranchList)
			setBranch(String(userInfo.branch?.id))
			setName(String(userInfo.name))
			setLoginId(String(userInfo.loginId))
			setUserType(String(userInfo.capability))
		}
	}, [userInfo])

	useLayoutEffect(() => {
		if (view) setView()
	}, [open])

	useEffect(() => {
		if (!loading && !error) {
			setBranchList(getBranches(loading, error, data, company))
		}
	}, [loading])

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault()
	}

	useEffect(() => {
		setBranchList(getBranches(loading, error, data, company))
		setBranch('')
	}, [company])

	useEffect(() => {
		if (userInfo !== undefined) {
			setBranch(String(userInfo.branch?.id))
		}
	}, [branchList])

	return (
		<Dialog open={open} onClose={onClose}>
			{!loading && !error && (
				<>
					<DialogTitle>ユーザー編集</DialogTitle>
					<DialogContent>
						<DialogContentText sx={{ mb: 3 }}>
							編集後保存ボタンを押してください。
						</DialogContentText>
						<CompanyBranch
							company={company}
							branch={branch}
							branchList={branchList}
							companies={
								!loading && !error ? data.allCompany : []
							}
							loading={loading}
							error={error}
							onChangeCompany={handleChangeCompany}
							onChangeBranch={handleChangeBranch}
							companyError={companyError}
							branchError={branchError}
							size={6}
						/>
						<Grid2 container spacing={1}>
							<Grid2 xs={12}>
								<FormControl sx={{ mt: 1, mb: 1 }}>
									<FormLabel id="capability-seelct">
										役職
									</FormLabel>
									<CapabilityRadio
										company={
											company !== ''
												? data.allCompany.find(
														(c: Company) =>
															c.id === company
												  ).name
												: ''
										}
										userType={userType}
										handleChangeUserType={
											handleChangeUserType
										}
									/>
								</FormControl>
							</Grid2>
						</Grid2>
						<Grid2 container spacing={1} sx={{ mb: 2 }}>
							<Grid2 xs={6}>
								<FormControl
									fullWidth
									sx={{ mt: 1, mb: 1 }}
									variant="standard"
								>
									<TextField
										label="名前"
										id="name-input"
										defaultValue={name}
										value={name}
										variant="standard"
										onChange={handleChangeName}
										error={nameError}
										helperText={
											nameError && '入力されていません'
										}
									/>
								</FormControl>
							</Grid2>
						</Grid2>
						<Grid2 container spacing={2}>
							<Grid2 xs={6}>
								<FormControl
									fullWidth
									sx={{ mt: 1, mb: 1 }}
									variant="standard"
								>
									<InputLabel htmlFor="dialog-userid">
										ユーザーID
									</InputLabel>
									<Input
										id="dialog-userid"
										disabled
										startAdornment={
											<InputAdornment position="start">
												{loginId}
											</InputAdornment>
										}
									/>
								</FormControl>
							</Grid2>
							<Grid2 xs={6}>
								<FormControl
									fullWidth
									sx={{ mt: 1, mb: 1 }}
									variant="standard"
								>
									<TextField
										label="パスワード"
										id="standard-size-normal"
										defaultValue={password}
										variant="standard"
										onChange={handleChangePassWord}
										error={passwordError}
										helperText={
											passwordError
												? '16文字以上入力してください'
												: '変更しない場合は空欄'
										}
									/>
								</FormControl>
							</Grid2>
						</Grid2>
					</DialogContent>
					<DialogActions sx={{ p: '1rem' }}>
						<Button onClick={onCancel}>キャンセル</Button>
						<Button
							onClick={(e) => {
								e.preventDefault()
								const input: UserChangeInput = {
									id: user.id as string,
									loginId: user.loginId as string,
									password: password,
									capability: userType,
									name: name,
									branchId: branch,
								}
								const flag = checkInput()
								if (!flag) {
									onSave(input)
								}
							}}
							variant="contained"
						>
							保存
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	)
}

// const EditDialog = () => {}
export default EditDialog
