import LoginForm from '../../components/form'

const Login = () => {
	return (
		<div className="login-container">
			<h1 className="login-container__title">調査データ取得システム</h1>
			<LoginForm />
		</div>
	)
}

export default Login
