import AdminBase from '../../components/admin-base/AdminBase'
import { Box, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import AnalyticsTableList from '../../components/analytics/AnalyticsTableList'
import { GET_COMPANIES_BRANCHES } from '../../components/gql/Queries'
import { useQuery } from '@apollo/client'
import Company from '../../types/Company'
import Branch from '../../types/Branch'
import CompanyBranch from '../../components/company-branch/CompanyBranch'

const Analytics = () => {
	const [company, setCompany] = useState<string>('')
	const [branch, setBranch] = useState<string>('')
	const [branchList, setBranchList] = useState<Branch[]>([])
	const { loading, error, data } = useQuery(GET_COMPANIES_BRANCHES)

	const branches = (companyId: string): Branch[] => {
		if (loading) return []
		if (error) return []

		const target = data.allCompany.find(
			(company: Company) => company.id === companyId
		)
		return target ? target.branches : []
	}

	const handleChangeCompany = (event: SelectChangeEvent) => {
		setCompany(event.target.value)
	}

	const handleChangeBranch = (event: SelectChangeEvent) => {
		setBranch(event.target.value as string)
	}

	useEffect(() => {
		setBranchList(branches(company))
		setBranch('')
	}, [company])

	return (
		<AdminBase title="今月の統計">
			<Box sx={{ pl: '1rem', pr: '1rem', pt: 2 }}>
				<CompanyBranch
					company={company}
					branch={branch}
					branchList={branchList}
					companies={!loading && !error ? data.allCompany : []}
					loading={loading}
					error={error}
					onChangeCompany={handleChangeCompany}
					onChangeBranch={handleChangeBranch}
				/>
				<Box sx={{ mt: 2 }}>
					<AnalyticsTableList companyId={company} branchId={branch} />
				</Box>
			</Box>
		</AdminBase>
	)
}

export default Analytics
