import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import _ from 'lodash'
import Branch from '../../types/Branch'
import Company from '../../types/Company'
import SettingTableData from '../../types/SettingTableData'
import SettingForm from './SettingForm'
import SettingTableList from './SettingTableList'

const SettingTableForm = ({
	type,
	defaultData,
	refetch,
	company = '',
	setCompanyError = () => {},
}: {
	type: string
	defaultData: Company[] | Branch[]
	refetch: (
		variables?: Partial<OperationVariables> | undefined
	) => Promise<ApolloQueryResult<any>>
	company?: string
	setCompanyError?: (companyError: boolean) => void
}) => {
	return (
		<Grid2 container spacing={2}>
			<Grid2 xs={12} md={4}>
				<SettingTableList
					type={type}
					defaultData={defaultData}
					refetch={refetch}
				/>
			</Grid2>
			<Grid2 xs={12} md={4}>
				<SettingForm
					type={type}
					refetch={refetch}
					company={company}
					setCompanyError={setCompanyError}
				/>
			</Grid2>
		</Grid2>
	)
}

export default SettingTableForm
