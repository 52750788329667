import { indigo, pink } from '@mui/material/colors'
import { createTheme } from '@mui/material'
import * as locales from '@mui/material/locale';

declare module '@mui/material/styles' {
	interface Theme {
		primary: {
			main: string
			secondary: string
		}
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		primary?: {
			main?: string
		}
	}
}

const customizeTheme = createTheme({
	palette: {
		primary: indigo,
		secondary: pink,
	},
	typography: {
		fontFamily: "'Noto Sans JP', sans-serif",
	},
}, locales['jaJP'])

export default customizeTheme;
