import { Close } from '@mui/icons-material'
import { IconButton, Snackbar } from '@mui/material'

const Notify = ({
	label,
	open,
	onClose,
}: {
	label: string
	open: boolean
	onClose: () => void
}) => {
	const action = (
		<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={onClose}
		>
			<Close fontSize="small" />
		</IconButton>
	)

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
			message={label}
			action={action}
		/>
	)
}

export default Notify
