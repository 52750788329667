import { createContext, ReactNode, useContext, useState } from 'react'
import SettingTableData, {
	SettingTableAdvancedData,
} from '../types/SettingTableData'
import _ from 'lodash'

interface SettingTableContextValue {
	nowData: SettingTableData[]
	addData(name: string, advanced?: SettingTableAdvancedData[]): void
	changeData(newData: SettingTableData): void
	deleteData(id: number): void
	initialSet(initialData: SettingTableData[]): void
}

const ManageDataContext = createContext<SettingTableContextValue>({
	nowData: [],
	addData: () => {},
	changeData: () => {},
	deleteData: () => {},
	initialSet: () => {},
})

export const useManageData = () => useContext(ManageDataContext)

const SettingTableProvider = ({ children }: { children?: ReactNode }) => {
	const [nowData, setNowData] = useState<SettingTableData[]>([])
	const [initialFlag, setinitialFlag] = useState<boolean>(false)

	const addData = (
		name: string,
		advanced?: SettingTableAdvancedData[] | undefined
	) => {
		const lastId = nowData.length === 0 ? 0 : nowData[nowData.length - 1].id
		const newNowData = [...nowData]
		const config: SettingTableData = {
			id: Number(lastId) + 1,
			name: name,
		}

		if (
			!_.isUndefined(advanced) &&
			_.isArray(advanced) &&
			advanced.length > 0
		) {
			config.advanced = advanced
		}

		newNowData.push(config)
		setNowData(newNowData)
	}

	const changeData = (newData: SettingTableData) => {
		const newNowData = [...nowData]
		const index = newNowData.map((d) => d.id).indexOf(newData.id)
		if (index === -1) return

		newNowData[index] = {
			...newNowData[index],
			...{
				name: newData.name,
				advanced: newData.advanced,
			},
		}

		setNowData(newNowData)
	}

	const deleteData = (id: number) => {
		let newNowData: SettingTableData[] = nowData
		const index = newNowData.map((d) => d.id).indexOf(id)
		if (index !== -1) {
			newNowData = nowData.filter((d, i) => i !== index)
		}

		setNowData(newNowData)
	}

	const initialSet = (initialData: SettingTableData[]) => {
		if (!initialFlag) {
			setNowData(initialData)
			setinitialFlag(true)
		}
	}

	return (
		<ManageDataContext.Provider
			value={{ nowData, addData, changeData, deleteData, initialSet }}
		>
			{children}
		</ManageDataContext.Provider>
	)
}

export default SettingTableProvider
