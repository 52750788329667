import { createContext, ReactNode, useContext, useState } from 'react'

interface CheckListContextValue {
	checked: number[]
	updateChecked(value: number): void
	clearChecked(): void
}

const CheckContext = createContext<CheckListContextValue>({
	checked: [],
	updateChecked: () => {},
	clearChecked: () => {},
})

export const useChecked = () => useContext(CheckContext)

const CheckedProvider = ({ children }: { children?: ReactNode }) => {
	const [checked, setChecked] = useState<number[]>([])

	const updateChecked = (value: number) => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	const clearChecked = () => {
		setChecked([])
	}

	return (
		<CheckContext.Provider value={{ checked, updateChecked, clearChecked }}>
			{children}
		</CheckContext.Provider>
	)
}

export default CheckedProvider
