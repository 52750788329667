import { useMutation } from '@apollo/client'
import { Button, Link } from '@mui/material'
import { indigo } from '@mui/material/colors'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToken } from '../../context/TokenProvider'
import User from '../../types/UserLogin'
import FormTextField from '../form/FormTextField'
import { LOGIN_USER } from '../gql/Mutation'

import './style.scss'

const LoginForm = () => {
	const [user, setUser] = useState<User>({ loginId: '', password: '' })
	const [isFirstViewUserId, setIsFirstViewUserId] = useState<boolean>(true)
	const [isFirstViewPass, setIsFirstViewPass] = useState<boolean>(true)
	const [login, { loading, error, data }] = useMutation(LOGIN_USER)
	const [loginError, setLoginError] = useState<boolean>(false)
	const navigate = useNavigate()
	const { setUserToken, setRedirect } = useToken()

	const setter = (fieldName: string, newValue: string) => {
		setUser({
			...user,
			...{
				[fieldName]: newValue,
			},
		})

		if (fieldName === 'userId' && isFirstViewUserId) {
			setIsFirstViewUserId(false)
		}
		if (fieldName === 'password' && isFirstViewPass) {
			setIsFirstViewPass(false)
		}
	}

	return (
		<form className="form">
			{loginError && (
				<p className="error-message">
					ログインに失敗しました。
					<br />
					ログイン情報を確認してください。
				</p>
			)}
			<FormTextField
				value={user.loginId}
				onChange={(newValue: string) => setter('loginId', newValue)}
				message="IDを入力してください。"
				id="id"
				isFirstView={isFirstViewUserId}
			/>

			<FormTextField
				value={user.password}
				onChange={(newValue: string) => setter('password', newValue)}
				message="パスワードを入力してください。"
				id="password"
				type="password"
				isFirstView={isFirstViewPass}
			/>

			<Link href="/search" sx={{ textDecoration: 'none' }}>
				<Button
					variant="contained"
					sx={{
						width: '100px',
						margin: '1rem auto',
						backgroundColor: indigo[800],
						'&:hover': {
							backgroundColor: indigo[800],
							opacity: 0.85,
						},
					}}
					onClick={(e) => {
						e.preventDefault()
						login({
							variables: {
								input: user,
							},
							onCompleted: (data) => {
								setUserToken(data.login)
								setRedirect(true)
							},
							onError: () => {
								setLoginError(true)
							},
						})
					}}
				>
					ログイン
				</Button>
			</Link>
		</form>
	)
}

export default LoginForm
