import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useToken } from '../../context/TokenProvider'

const Admin = ({ children }: { children?: ReactNode }) => {
	const { loadingAll, user } = useToken()

	if (!loadingAll && !user) {
		return <Navigate to="/" replace />
	}

	if (!loadingAll && user?.capability !== 'admin') {
		return <Navigate to="/search" replace />
	}

	return <>{!loadingAll && <>{children}</>}</>
}

export default Admin
