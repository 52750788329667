import Branch from "../types/Branch"
import Company from "../types/Company"
import { ApolloError } from "@apollo/client"

const getBranches = (loading: boolean, error: ApolloError | undefined, data: any, companyId: string): Branch[] => {
	if (loading) return []
	if (error) return []

	const target = data.allCompany.find(
		(company: Company) => company.id === companyId
	)
	return target ? target.branches : []
}

export default getBranches
