import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

const CHISYO = [
	{
		name: '取締役',
		value: 'chisyo_torishimari',
	},
	{
		name: '管理部',
		value: 'chisyo_kanri',
	},
	{
		name: '支店長',
		value: 'chisyo_branch',
	},
	{
		name: '一般社員',
		value: 'chisyo_ippan',
	},
]

const DEFAULT = [
	{
		name: '取締役',
		value: 'torishimari',
	},
	{
		name: '職員',
		value: 'staff',
	},
	{
		name: '利用者',
		value: 'riyosya',
	},
]

const CapabilityRadio = ({
	company,
	userType,
	handleChangeUserType,
}: {
	company: string
	userType: string
	handleChangeUserType: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
	return (
		<RadioGroup
			row
			aria-labelledby="capability-seelct"
			name="row-radio-buttons-group"
			defaultValue={userType}
			value={userType}
			onChange={handleChangeUserType}
		>
			{company === '東日本地所'
				? CHISYO.map((capability, index) => {
						return (
							<FormControlLabel
								value={capability.value}
								control={
									<Radio
										checked={userType === capability.value}
									/>
								}
								label={capability.name}
								key={capability.value}
							/>
						)
				  })
				: DEFAULT.map((capability, index) => {
						return (
							<FormControlLabel
								value={capability.value}
								control={
									<Radio
										checked={userType === capability.value}
									/>
								}
								label={capability.name}
								key={capability.value}
							/>
						)
				  })}
		</RadioGroup>
	)
}

export default CapabilityRadio
