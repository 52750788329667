import {
	Box,
	CssBaseline,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tab,
	Tabs,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import AdminBase from '../../components/admin-base/AdminBase'
import SettingTableForm from '../../components/setting-table'
import TabPanel from '../../components/tab-panel/TabPanel'
import SettingTableProvider from '../../context/SettingTableProvider'
import branchData from '../../data/baranch.json'
import postData from '../../data/post.json'
import companyData from '../../data/company.json'
import { useQuery } from '@apollo/client'
import { GET_COMPANIES_BRANCHES } from '../../components/gql/Queries'
import getBranches from '../../utils/getBranches'
import Branch from '../../types/Branch'
import Company from '../../types/Company'

const prefix = 'settings'

const Settings = () => {
	const [value, setValue] = useState(0)
	const [company, setCompany] = useState<string>('')
	const [companyError, setCompanyError] = useState<boolean>(false)
	const [branchList, setBranchList] = useState<Branch[]>([])
	const { loading, error, data, refetch } = useQuery(GET_COMPANIES_BRANCHES)

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	const handleChangeCompany = (event: SelectChangeEvent) => {
		setCompany(event.target.value)
	}

	useEffect(() => {
		setBranchList(getBranches(loading, error, data, company))
		setCompanyError(false)
	}, [company])

	useEffect(() => {
		if (!loading && !error) {
			setBranchList(getBranches(loading, error, data, company))
		}
	}, [data])

	const BranchSettingTable = () => {
		return (
			<SettingTableProvider>
				<SettingTableForm
					defaultData={branchList}
					type="branch"
					refetch={refetch}
					company={company}
					setCompanyError={setCompanyError}
				/>
			</SettingTableProvider>
		)
	}

	return (
		<AdminBase title="設定">
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
					margin: '0 24px',
					maxWidth: '100%',
				}}
			>
				<CssBaseline enableColorScheme />
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					textColor="secondary"
					indicatorColor="secondary"
				>
					<Tab label="会社名" />
					<Tab label="支店名" />
					{/* <Tab label="権限" /> */}
				</Tabs>
			</Box>
			<TabPanel value={value} index={0} prefix={prefix}>
				{!loading && !error && (
					<SettingTableForm
						defaultData={data.allCompany}
						type="company"
						refetch={refetch}
					/>
				)}
			</TabPanel>
			<TabPanel value={value} index={1} prefix={prefix}>
				<FormControl sx={{ width: '150px' }} error={companyError}>
					<InputLabel id="company-branch-label">会社</InputLabel>
					<Select
						labelId="company-branch-label"
						id="company-branch-select"
						value={company}
						label="会社"
						onChange={handleChangeCompany}
					>
						{!loading &&
							!error &&
							data.allCompany.map((aConmpany: Company) => (
								<MenuItem
									key={aConmpany.id}
									value={String(aConmpany.id)}
								>
									{aConmpany.name}
								</MenuItem>
							))}
					</Select>
					{companyError && (
						<FormHelperText>選択されていません</FormHelperText>
					)}
				</FormControl>
				<BranchSettingTable />
			</TabPanel>
			{/* <TabPanel value={value} index={2} prefix={prefix}>
				<SettingTableProvider>
					<SettingTableForm defaultData={postData} typeLabel="役職" />
				</SettingTableProvider>
			</TabPanel> */}
		</AdminBase>
	)
}

export default Settings
