import {
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Modal,
} from '@mui/material'
import { useChecked } from '../../context/CheckedProvider'
import './style.scss'
import { RemoveCircle, FileDownload, Autorenew } from '@mui/icons-material'
import { indigo, red } from '@mui/material/colors'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_FILES } from '../gql/Queries'
import File from '../../types/File'
import axios from 'axios'
import { useToken } from '../../context/TokenProvider'
import { useNavigate } from 'react-router-dom'
import LoadingAnimation from '../loading/LoadingAnimation'

const drawerWidth = 240

const SidebarList = ({}) => {
	const {
		loading,
		error,
		data: fileData,
	} = useQuery(GET_FILES, {
		variables: {
			after: 0,
			limit: 100000,
		},
	})
	const { checked, updateChecked, clearChecked } = useChecked()
	const [isDownloading, setIsDownloading] = useState<boolean>(false)
	const { token } = useToken()
	const navigate = useNavigate()
	const [open, setOpen] = useState(false)

	const modalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 300,
		bgcolor: '#ffffffe0',
		borderRadius: '8px',
		p: 4,
	}

	let text = 'ダウンロード'
	let icon = <FileDownload className="sidebar__button-icon" />
	if (isDownloading) {
		text = 'Donwloading...'
		icon = (
			<Autorenew className="sidebar__button-icon sidebar__button-icon--loading" />
		)
	}

	const loadingEvent = () => {
		setIsDownloading(true)
		setOpen(true)

		const variables = {
			fileIds: checked,
		}

		const config = {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json;',
				'Access-Control-Allow-Origin': '*',
				authorization: `Bearer ${token}`,
			},
		}

		axios
			.post(
				// 'http://localhost:89/google-drive-data-system/msgraph-download-files.php',
				// 'https://kuuuuuma.com/msgraph-download-files.php',
				'https://grandcity.group/msgraph-download-files.php',
				{
					fileIds: checked,
				},
				config
			)
			.then((response) => {
				if (response.data.status === 200) {
					const a = document.createElement('a')
					a.setAttribute(
						'href',
						'data:text/plain;base64,' + response.data.file
					)
					a.setAttribute('download', '調査データ.zip')

					a.style.display = 'none'
					document.body.appendChild(a)

					a.click()
					document.body.removeChild(a)
				} else if (response.data.status === 403) {
					alert(
						'セッションの有効期限が切れているため再度ログインしてください。'
					)
					navigate('/')
				}

				setIsDownloading(false)
				clearChecked()
				setOpen(false)
			})
			.catch((error) => {
				console.log(error)
				setIsDownloading(false)
				setOpen(false)
			})
	}

	return (
		<Drawer
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: drawerWidth,
					boxSizing: 'border-box',
					right: open ? 17 : 0,
				},
				display: 'flex',
				flexDirection: 'column',
			}}
			variant="permanent"
			anchor="right"
			className="sidebar"
		>
			<h3 className="sidebar-title">選択アイテム</h3>
			<span className="sidebar-subtitle">
				<span className="sidebar-subtitle__count">
					{checked.length}
				</span>
				件選択中
			</span>
			<Divider />
			<List
				className="sidebar-list"
				sx={{ width: '100%', flexGrow: 1, overflowY: 'auto' }}
			>
				{checked.map((checkedData: number, index: number) => {
					const text = fileData.files.find(
						(file: File) => file.id === String(checkedData)
					).filename
					const labelId = `check-list-${index}`

					return (
						<ListItem
							key={index}
							secondaryAction={
								<IconButton
									edge="end"
									aria-label="remove"
									onClick={(e) => updateChecked(checkedData)}
								>
									<RemoveCircle sx={{ color: red[700] }} />
								</IconButton>
							}
							className="sidebar-list__item"
							divider={true}
						>
							<ListItemText
								id={labelId}
								primary={text}
								className="sidebar-list__text"
								sx={{
									display: '-webkit-box',
									WebkitLineClamp: 2,
									WebkitBoxOrient: 'vertical',
									overflow: 'hidden',
								}}
							/>
						</ListItem>
					)
				})}
			</List>
			<Divider />
			<Button
				className="sidebar-list__button"
				variant="contained"
				startIcon={icon}
				disabled={checked.length > 0 ? false : true}
				sx={{
					width: 'calc( 100% - 2rem )',
					margin: '1rem',
					backgroundColor: indigo[800],
					'&:hover': {
						backgroundColor: indigo[800],
						opacity: 0.85,
					},
				}}
				onClick={(e) => loadingEvent()}
			>
				{text}
			</Button>
			<Modal
				open={open}
				onClose={() => {}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={modalStyle}>
					<LoadingAnimation label="ダウンロード中" />
				</Box>
			</Modal>
		</Drawer>
	)
}

export default SidebarList
