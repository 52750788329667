import { Feed, FileDownload, QueryStats, Settings } from '@mui/icons-material'
import {
	Button,
	Divider,
	Drawer,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material'
import { indigo } from '@mui/material/colors'
import { NavLink, useNavigate } from 'react-router-dom'
import { useToken } from '../../context/TokenProvider'
import Menu from '../../types/Menu'

import './style.scss'

const menus = [
	{
		text: '統計',
		link: '/analytics',
		icon: <QueryStats sx={{ color: indigo[800] }} />,
	},
	{
		text: 'ユーザー管理',
		link: '/user-manage',
		icon: <Feed sx={{ color: indigo[800] }} />,
	},
	{
		text: '出力',
		link: '/output',
		icon: <FileDownload sx={{ color: indigo[800] }} />,
	},
	{
		text: '設定',
		link: '/settings',
		icon: <Settings sx={{ color: indigo[800] }} />,
	},
]

const drawerWidth = 220
const SidebarMenu = () => {
	const { removeUserToken } = useToken()
	const navigate = useNavigate()

	return (
		<Drawer
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: drawerWidth,
					boxSizing: 'border-box',
				},
				display: 'flex',
				flexDirection: 'column',
			}}
			variant="permanent"
			anchor="left"
			className="sidebar"
		>
			<h3
				className="sidebar-title"
				style={{ marginBottom: '1rem', height: 27 }}
			>
				管理画面
			</h3>
			<Divider />
			<List
				className="sidebar-list sidebar-menu"
				sx={{ width: '100%', flexGrow: 1, overflowY: 'auto', pt: 0 }}
			>
				{menus.map((menu: Menu, index: number) => {
					const labelId = `menu-item-${index}`

					return (
						<NavLink
							className={({ isActive }) =>
								isActive
									? 'sidebar-link sidebar-link--active'
									: 'sidebar-link'
							}
							to={menu.link}
							key={index}
						>
							<ListItem
								className="sidebar-list__item"
								divider={true}
								id={labelId}
							>
								<ListItemButton>
									<ListItemIcon>{menu.icon}</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={menu.text}
										className="sidebar-list__text sidebar-menu__text"
										sx={{
											color: indigo[800],
										}}
									/>
								</ListItemButton>
							</ListItem>
						</NavLink>
					)
				})}
			</List>
			<Divider />
			<Link href="/" sx={{ textDecoration: 'none', width: '100%' }}>
				<Button
					sx={{ width: 'calc(100% - 2rem)', m: '1rem' }}
					onClick={() => {
						removeUserToken()
						navigate('/')
					}}
					variant="contained"
				>
					ログアウト
				</Button>
			</Link>
		</Drawer>
	)
}

export default SidebarMenu
