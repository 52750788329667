import { gql } from '@apollo/client'

export const GET_COMPANIES_BRANCHES = gql`
	query getBranchesQueries {
		allCompany {
			id
			name
			branches {
				id
				name
			}
		}
	}
`

export const GET_USERS_BY_COMPANY_BRANCH = ({
	companyId = '',
	branchId = '',
	after = 0,
	limit = 10,
	start = '',
	end = '',
}: {
	companyId?: string
	branchId?: string
	after?: number
	limit?: number
	start?: string
	end?: string
}) => {
	let usersQuery = `
		after: ${after}
		limit: ${limit}
	`
	if (companyId) {
		usersQuery += ` companyId: "${companyId}"`
	}
	if (branchId) {
		usersQuery += ` branchId: "${branchId}"`
	}

	let dateLimit = ''
	if (start) {
		dateLimit += ` start: "${start}"`
	}
	if (end) {
		dateLimit += ` end: "${end}"`
	}

	if (dateLimit !== '') {
		dateLimit = '(' + dateLimit + ')'
	}

	return gql`
	query getUsersByCompany {
		users(${usersQuery}) {
			id
			loginId
			name
			capability
			company {
				id
				name
			}
			branch {
				id
				name
			}
			downloadFiles ${dateLimit} {
				id
				filename
			}
			downloadZips ${dateLimit} {
				id
			}
			access {
				loginDate
			}
		}
	}
	`
}

export const GET_USERS_COUNT_BY_COMPANY_BRANCH = ({
	companyId = '',
	branchId = '',
}: {
	companyId?: string
	branchId?: string
}) => {
	let usersQuery = ''
	if (companyId) {
		usersQuery += `companyId: "${companyId}"`
	}
	if (branchId) {
		usersQuery += `branchId: "${branchId}"`
	}

	if (usersQuery !== '') {
		usersQuery = '(' + usersQuery + ')'
	}

	return gql`
	query getAllUsers {
		allUsers ${usersQuery} {
			id
		}
	}
	`
}

export const GET_YEARS = gql`
	query getYears {
		years
	}
`

export const GET_USER = gql`
	query getUser {
		user {
			id
			name
			loginId
			capability
			company {
				id
				name
			}
			branch {
				id
				name
			}
		}
	}
`

export const GET_FILES = gql`
	query getFiles($after: Int, $limit: Int) {
		files(after: $after, limit: $limit) {
			id
			filename
		}
	}
`
