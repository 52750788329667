import { Box, CssBaseline, SelectChangeEvent, Tab, Tabs } from '@mui/material'
import { useState, useEffect } from 'react'
import AdminBase from '../../components/admin-base/AdminBase'
import TabPanel from '../../components/tab-panel/TabPanel'
import UserList from '../../components/User/UserList'
import UserNew from '../../components/User/UserNew'
import { useQuery } from '@apollo/client'
import { GET_COMPANIES_BRANCHES } from '../../components/gql/Queries'
import Branch from '../../types/Branch'
import getBranches from '../../utils/getBranches'
import CompanyBranch from '../../components/company-branch/CompanyBranch'

const a11yProps = (index: number) => {
	return {
		id: `user-manage-tab-${index}`,
		'aria-controls': `user-manage-tabpanel-${index}`,
	}
}

const prefix = 'user-manage'

const UserManage = () => {
	const [company, setCompany] = useState<string>('')
	const [branch, setBranch] = useState<string>('')
	const [branchList, setBranchList] = useState<Branch[]>([])
	const [value, setValue] = useState(0)
	const { loading, error, data } = useQuery(GET_COMPANIES_BRANCHES)

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	const handleChangeCompany = (event: SelectChangeEvent) => {
		setCompany(event.target.value)
	}

	const handleChangeBranch = (event: SelectChangeEvent) => {
		setBranch(event.target.value as string)
	}

	useEffect(() => {
		setBranchList(getBranches(loading, error, data, company))
		setBranch('')
	}, [company])

	return (
		<AdminBase title="ユーザー管理">
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
					margin: '0 1rem',
					maxWidth: '100%',
				}}
			>
				<CssBaseline enableColorScheme />
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					textColor="secondary"
					indicatorColor="secondary"
				>
					<Tab label="ユーザー一覧" {...a11yProps(0)} />
					<Tab label="新規ユーザー" {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0} prefix={prefix}>
				<CompanyBranch
					company={company}
					branch={branch}
					branchList={branchList}
					companies={!loading && !error ? data.allCompany : []}
					loading={loading}
					error={error}
					onChangeCompany={handleChangeCompany}
					onChangeBranch={handleChangeBranch}
				/>
				<Box sx={{ mt: 2 }}>
					<UserList companyId={company} branchId={branch} />
				</Box>
			</TabPanel>
			<TabPanel value={value} index={1} prefix={prefix}>
				<UserNew />
			</TabPanel>
		</AdminBase>
	)
}

export default UserManage
