import { TextField, TextFieldProps } from '@mui/material'

const FormTextField = ({
	value,
	onChange,
	message = '',
	isFirstView = false,
	variant = 'outlined',
	type = '',
	id = '',
	label = '',
	ariaLabel = '',
}: {
	value: string
	onChange: Function
	message?: string
	isFirstView?: boolean
	variant?: 'outlined' | 'filled' | 'standard'
	type?: string
	id?: string
	label?: string
	ariaLabel?: string
}) => {
	const newLabel = id && !label ? id : label
	const newAriaLabel = id && !ariaLabel ? id : ariaLabel

	const props: TextFieldProps = {
		id: id,
		label: newLabel,
		inputProps: { 'aria-label': newAriaLabel },
		onChange: (e) => onChange(e.target.value),
		variant: variant,
		value: value,
	}

	if (!isFirstView && value === '') {
		props.error = true
		props.helperText = message
	}

	if (type) {
		props.type = type
	}

	return <TextField {...props} />
}
export default FormTextField
