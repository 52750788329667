import { Box } from '@mui/system'
import TabPanelProps from '../../types/TabPanelProps'

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, prefix, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`${prefix}-tabpanel-${index}`}
			aria-labelledby={`${prefix}-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: ' 1.5rem 1rem' }}>{children}</Box>
			)}
		</div>
	)
}

export default TabPanel
