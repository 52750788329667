import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import customizeTheme from './utils/customizeTheme'
import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
} from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { ApolloProvider } from '@apollo/client/react'
import TokenProvider from './context/TokenProvider'

const httpLink = createHttpLink({
	// uri: 'http://localhost:89/google-drive-data-system/graphql.php',
	// uri: 'https://kuuuuuma.com/graphql.php',
	uri: 'https://grandcity.group/graphql.php',
})

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('token')
	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : '',
		},
	}
})

const client = new ApolloClient({
	// uri: 'http://localhost:89/google-drive-data-system/graphql.php',
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider theme={customizeTheme}>
				<ApolloProvider client={client}>
					<TokenProvider>
						<App />
					</TokenProvider>
				</ApolloProvider>
			</ThemeProvider>
		</BrowserRouter>
	</React.StrictMode>
)

reportWebVitals(console.log)
