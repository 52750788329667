import { TextField, TextFieldProps } from '@mui/material'

const SearchTextField = ({
	value,
	onChange,
	helper = '',
	variant = 'standard',
	id = '',
}: {
	value: string
	onChange: Function
	helper?: string
	variant?: 'outlined' | 'filled' | 'standard'
	id: string
}) => {
	const props: TextFieldProps = {
		id: id,
		helperText: helper,
		onChange: (e) => onChange(e.target.value),
		variant: variant,
		value: value,
		sx: {
			width: '100%',
			maxWidth: '600px',
			fontSize: '1.25rem',
		},
	}

	return <TextField {...props} />
}

export default SearchTextField
