import Capability from "../types/Capability";

export const capabilityMap = {
	chisyo_torishimari: '東日本地所 取締役',
	chisyo_kanri: '東日本地所 管理部',
	chisyo_branch: '東日本地所 支店長',
	chisyo_ippan: '東日本地所 一般社員',
	torishimari: '取締役',
	staff: '職員',
	riyosya: '利用者'
}



const getCapabilityLabel = (capability: Capability) => capabilityMap[capability];

export default getCapabilityLabel;
