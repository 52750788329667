import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

const LoadingAnimation = ({ label = '' }: { label?: string }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<CircularProgress sx={{ mt: 2, mb: 2 }} />
			{label !== '' && (
				<span
					style={{
						color: 'rgba(0, 0, 0, 0.6)',
						display: 'block',
						fontSize: '.75rem',
					}}
				>
					{label}
				</span>
			)}
		</Box>
	)
}

export default LoadingAnimation
