import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import Branch from '../../types/Branch'
import { ApolloError } from '@apollo/client'
import Company from '../../types/Company'

const CompanyBranch = ({
	company,
	branch,
	loading,
	error,
	companies,
	branchList,
	onChangeCompany,
	onChangeBranch,
	companyError = false,
	branchError = false,
	size = 3,
}: {
	company: string
	branch: string
	loading: boolean
	error: ApolloError | undefined
	companies: Company[]
	branchList: Branch[]
	onChangeCompany: (e: SelectChangeEvent) => void
	onChangeBranch: (e: SelectChangeEvent) => void
	companyError?: boolean
	branchError?: boolean
	size?: number
}) => {
	return (
		<Grid2 container spacing={1}>
			<Grid2 xs={size}>
				<FormControl fullWidth error={companyError}>
					<InputLabel id="company-branch-label">会社</InputLabel>
					<Select
						labelId="company-branch-label"
						id="company-branch-select"
						value={company}
						label="会社"
						onChange={onChangeCompany}
						sx={{ minWidth: '150px' }}
					>
						<MenuItem value="">会社選択</MenuItem>
						{!loading &&
							!error &&
							companies.map((aConmpany: Company) => (
								<MenuItem
									key={aConmpany.id}
									value={aConmpany.id}
								>
									{aConmpany.name}
								</MenuItem>
							))}
					</Select>
					{companyError && (
						<FormHelperText>選択されていません</FormHelperText>
					)}
				</FormControl>
			</Grid2>
			<Grid2 xs={size}>
				<FormControl fullWidth error={branchError}>
					<InputLabel id="branch-select-label">支店</InputLabel>
					<Select
						labelId="branch-select-label"
						id="branch-select"
						value={branch}
						label="支店"
						onChange={onChangeBranch}
						sx={{ minWidth: '150px' }}
					>
						<MenuItem value="">支店選択</MenuItem>
						{branchList.map((aBranchData) => {
							return (
								<MenuItem
									key={aBranchData.id}
									value={aBranchData.id}
								>
									{aBranchData.name}
								</MenuItem>
							)
						})}
					</Select>
					{companyError && (
						<FormHelperText>選択されていません</FormHelperText>
					)}
				</FormControl>
			</Grid2>
		</Grid2>
	)
}

export default CompanyBranch
