import {
	ApolloQueryResult,
	OperationVariables,
	useMutation,
} from '@apollo/client'
import { Box, Button, FormControl, TextField } from '@mui/material'
import { useState } from 'react'
import { NEW_BRANCH, NEW_COMPANY } from '../gql/Mutation'
import Notify from '../notify/Notify'

const SettingForm = ({
	type,
	refetch,
	company = '',
	setCompanyError = () => {},
}: {
	type: string
	refetch: (
		variables?: Partial<OperationVariables> | undefined
	) => Promise<ApolloQueryResult<any>>
	company?: string
	setCompanyError: (companyError: boolean) => void
}) => {
	const [name, setName] = useState<string>('')
	const [nameError, setNameError] = useState<boolean>(false)
	const [openAddNotify, setOpenAddNotify] = useState<boolean>(false)
	const [
		newCompany,
		{
			loading: newCompanyLoading,
			error: newCompanyError,
			data: newCompanyData,
		},
	] = useMutation(NEW_COMPANY)
	const [
		newBranch,
		{
			loading: newBranchLoading,
			error: newBranchError,
			data: newBranchData,
		},
	] = useMutation(NEW_BRANCH)

	const checkInput = () => {
		let flag = false

		if (type === 'branch' && company === '') {
			setCompanyError(true)
			flag = true
		}

		if (name === '') {
			setNameError(true)
			flag = true
		}

		return flag
	}

	const clearForm = () => {
		setName('')
	}

	const typeLabel = type === 'company' ? '会社名' : '支店名'

	return (
		<Box sx={{ width: '100%', maxWidth: '100%' }}>
			<FormControl fullWidth sx={{ m: 1 }} variant="standard">
				<TextField
					label={typeLabel}
					id="name-input"
					value={name}
					variant="standard"
					onChange={(e) => setName(e.target.value)}
					error={nameError}
					helperText={nameError && '入力されていません'}
				/>
			</FormControl>
			<FormControl
				sx={{
					m: 1,
					mt: 2,
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				<Button
					sx={{ width: '100px' }}
					onClick={(e) => {
						e.preventDefault()
						const options = {
							variables: {
								name: name,
								companyId: company,
							},
							onCompleted: () => {
								refetch()
								setOpenAddNotify(true)
							},
						}
						const check = checkInput()
						if (!check) {
							type === 'company'
								? newCompany(options)
								: newBranch(options)

							clearForm()
						}
					}}
					variant="contained"
				>
					追加
				</Button>
			</FormControl>
			<Notify
				label={`${typeLabel}を追加しました。`}
				open={openAddNotify}
				onClose={() => setOpenAddNotify(false)}
			/>
		</Box>
	)
}

export default SettingForm
