import { gql } from '@apollo/client'

export const NEW_USER = gql`
	mutation newUser($input: UserInput!) {
		newUser(input: $input)
	}
`

export const CHANGE_USER = gql`
	mutation changeUser($input: UserChangeInput!) {
		changeUser(input: $input) {
			id
		}
	}
`

export const DELETE_USER = gql`
	mutation deleteUser($id: ID!) {
		deleteUser(id: $id)
	}
`

export const NEW_COMPANY = gql`
	mutation newCompany($name: String!) {
		newCompany(name: $name) {
			id
			name
		}
	}
`

export const CHANGE_COMPANY = gql`
	mutation changeCompany($id: ID!, $name: String!) {
		changeCompany(id: $id, name: $name)
	}
`

export const DELETE_COMPANY = gql`
	mutation deleteCompany($id: ID!) {
		deleteCompany(id: $id)
	}
`

export const NEW_BRANCH = gql`
	mutation newBranch($companyId: ID!, $name: String!) {
		newBranch(companyId: $companyId, name: $name) {
			id
			name
		}
	}
`

export const CHANGE_BRANCH = gql`
	mutation changeBranch($id: ID!, $name: String!) {
		changeBranch(id: $id, name: $name)
	}
`

export const DELETE_BRANCH = gql`
	mutation deleteBranch($id: ID!) {
		deleteBranch(id: $id)
	}
`

export const LOGIN_USER = gql`
	mutation login($input: UserLoginInput!) {
		login(input: $input)
	}
`

export const VERIFY_TOKEN = gql`
	mutation verifyToken($token: String!) {
		verifyToken(token: $token)
	}
`
