import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useEffect, useState } from 'react'
import { Delete, Edit } from '@mui/icons-material'
import TableHeadCell from '../../types/TableHeadCell'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Skeleton,
} from '@mui/material'
import { indigo, red } from '@mui/material/colors'
import EditDialog from './EditDialog'
import { useMutation, useQuery } from '@apollo/client'
import {
	GET_USERS_BY_COMPANY_BRANCH,
	GET_USERS_COUNT_BY_COMPANY_BRANCH,
} from '../gql/Queries'
import User from '../../types/User'
import getCapabilityLabel from '../../utils/getCapabilityLabel'
import { CHANGE_USER, DELETE_USER } from '../gql/Mutation'
import UserChangeInput from '../../types/UserChangeInput'
import Notify from '../notify/Notify'
import Capability from '../../types/Capability'

const headCells: readonly TableHeadCell[] = [
	{
		id: 'userId',
		label: 'ユーザーID',
		align: 'left',
	},
	{
		id: 'name',
		label: 'ユーザー名',
		align: 'left',
	},
	{
		id: 'company',
		label: '会社',
		align: 'center',
		width: 180,
	},
	{
		id: 'branch',
		label: '支店',
		align: 'center',
		width: 180,
	},
	{
		id: 'manage',
		label: '役職',
		align: 'center',
		width: 180,
	},
]

const UserList = ({
	companyId = '',
	branchId = '',
}: {
	branchId?: string
	companyId?: string
}) => {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(5)
	const [count, setCount] = useState(0)
	const [openEdit, setOpenEdit] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [currentUser, setCurrentUser] = useState<User>()
	const [openEditNotify, setOpenEditNotify] = useState<boolean>(false)
	const [openDeleteNotify, setOpenDeleteNotify] = useState<boolean>(false)
	const { loading, error, data, refetch, startPolling, stopPolling } =
		useQuery(
			GET_USERS_BY_COMPANY_BRANCH({
				companyId: companyId,
				branchId: branchId,
				after: page * rowsPerPage,
				limit: rowsPerPage,
			})
		)
	const {
		loading: userCountLoading,
		error: userCountError,
		data: userCountData,
	} = useQuery(
		GET_USERS_COUNT_BY_COMPANY_BRANCH({
			companyId: companyId,
			branchId: branchId,
		})
	)
	const [
		changeUser,
		{
			data: changeUserData,
			loading: changeUserLoding,
			error: changeUserError,
		},
	] = useMutation(CHANGE_USER)
	const [
		deleteUser,
		{
			data: deleteUserData,
			loading: deleteUserLoging,
			error: deleteUserError,
		},
	] = useMutation(DELETE_USER)

	const handleClickOpenEdit = () => {
		startPolling(500)
		setOpenEdit(true)
	}

	const handleCloseEdit = () => {
		setOpenEdit(false)
		stopPolling()
	}

	const handleClickOpenDelete = () => {
		startPolling(500)
		setOpenDelete(true)
	}

	const handleCloseDelete = () => {
		setOpenDelete(false)
		stopPolling()
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleSave = (input: UserChangeInput) => {
		changeUser({
			variables: {
				input: input,
			},
			onCompleted: () => setOpenEditNotify(true),
		})
		setOpenEdit(false)
	}

	useEffect(() => {
		if (!userCountLoading && !userCountError) {
			setCount(userCountData.allUsers.length)
		}
	}, [userCountLoading])

	useEffect(() => {
		if (!changeUserLoding && !changeUserError) {
			stopPolling()
		}
	}, [changeUserLoding])

	useEffect(() => {
		if (!deleteUserLoging && !deleteUserError) {
			stopPolling()
			refetch()
			setCount(count - 1)
		}
	}, [deleteUserLoging])

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 && !userCountLoading && !userCountError
			? Math.max(
					0,
					(1 + page) * rowsPerPage - userCountData.allUsers.length
			  )
			: 0

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								{headCells.map((headCell) => (
									<TableCell
										key={headCell.id}
										align={headCell.align}
										sx={{
											width: `${headCell.width}px`,
										}}
									>
										{headCell.label}
									</TableCell>
								))}
								<TableCell
									align="center"
									sx={{
										width: '150px',
									}}
								/>
							</TableRow>
						</TableHead>
						<TableBody>
							{!loading && !error
								? data.users.map((row: User, index: number) => {
										const labelId = `enhanced-table-checkbox-${index}`

										return (
											<TableRow
												hover
												tabIndex={-1}
												key={row.id}
											>
												<TableCell
													component="th"
													id={labelId}
													scope="row"
													align="left"
												>
													{row.loginId}
												</TableCell>
												<TableCell align="left">
													{row.name}
												</TableCell>
												<TableCell align="center">
													{row.company?.name}
												</TableCell>
												<TableCell align="center">
													{row.branch?.name}
												</TableCell>
												<TableCell align="center">
													{getCapabilityLabel(
														row.capability as Capability
													)}
												</TableCell>
												<TableCell align="center">
													<Grid2
														container
														spacing={0}
													>
														<Grid2 xs={6}>
															<IconButton
																edge="end"
																aria-label="edit"
																onClick={(
																	e
																) => {
																	handleClickOpenEdit()
																	setCurrentUser(
																		row
																	)
																}}
															>
																<Edit color="primary" />
															</IconButton>
														</Grid2>
														<Grid2 xs={6}>
															<IconButton
																edge="end"
																aria-label="remove"
																onClick={(
																	e
																) => {
																	handleClickOpenDelete()
																	setCurrentUser(
																		row
																	)
																}}
															>
																<Delete
																	sx={{
																		color: red[700],
																	}}
																/>
															</IconButton>
														</Grid2>
													</Grid2>
												</TableCell>
											</TableRow>
										)
								  })
								: Array.from(Array(rowsPerPage).keys()).map(
										(num, index) => (
											<TableRow
												hover
												tabIndex={-1}
												key={`row-${num}`}
											>
												{[0, 1, 2, 3, 4, 5].map((n) => (
													<TableCell
														component="th"
														scope="row"
														align="left"
														key={`cell-${num}-${n}`}
													>
														<Skeleton
															variant="text"
															animation="wave"
															sx={{
																fontSize:
																	'1rem',
																bgcolor:
																	indigo[50],
																minHeight:
																	'21px',
																mt: '10px',
																mb: '10px',
																lineHeight: 1,
															}}
														/>
													</TableCell>
												))}
											</TableRow>
										)
								  )}
							{!loading && !error && emptyRows > 0 && (
								<TableRow
									style={{
										height: 73 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="表示数"
				/>
				<EditDialog
					userInfo={currentUser as User}
					open={openEdit}
					onClose={handleCloseEdit}
					onSave={handleSave}
					onCancel={handleCloseEdit}
				/>
				<Dialog open={openDelete} onClose={handleCloseDelete}>
					<DialogTitle>ユーザー削除</DialogTitle>
					<DialogContent>
						<DialogContentText>
							本当に削除してもよろしですか。
							<br />
							ダウンロード履歴やアクセス履歴も削除されます。
						</DialogContentText>
					</DialogContent>
					<DialogActions sx={{ p: '1rem' }}>
						<Button onClick={handleCloseDelete}>キャンセル</Button>
						<Button
							color="error"
							onClick={(e) => {
								e.preventDefault()
								deleteUser({
									variables: {
										id: currentUser?.id,
									},
									onCompleted: () =>
										setOpenDeleteNotify(true),
								})
								handleCloseDelete()
							}}
							variant="contained"
						>
							削除
						</Button>
					</DialogActions>
				</Dialog>
				<Notify
					label="ユーザー情報を変更しました。"
					open={openEditNotify}
					onClose={() => setOpenEditNotify(false)}
				/>
				<Notify
					label="ユーザーを削除しました。"
					open={openDeleteNotify}
					onClose={() => setOpenDeleteNotify(false)}
				/>
			</Paper>
		</Box>
	)
}

export default UserList
