import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	Input,
	InputAdornment,
	InputLabel,
	Paper,
	SelectChangeEvent,
	TextField,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useEffect, useLayoutEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import generatePassword from '../../utils/generatePassword'
import CompanyBranch from '../company-branch/CompanyBranch'
import Branch from '../../types/Branch'
import { useMutation, useQuery } from '@apollo/client'
import { GET_COMPANIES_BRANCHES } from '../gql/Queries'
import getBranches from '../../utils/getBranches'
import { NEW_USER } from '../gql/Mutation'
import Notify from '../notify/Notify'
import CapabilityRadio from '../capability-radio/CapabilityRadio'
import Company from '../../types/Company'

const UserNew = () => {
	const [uniqueId, setUniqueId] = useState<string>(uuid().slice(0, 8))
	const [password, setPassWord] = useState<string>(generatePassword(16))
	const [userType, setUserType] = useState<string>('riyosya')
	const [company, setCompany] = useState<string>('')
	const [branch, setBranch] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [branchList, setBranchList] = useState<Branch[]>([])
	const { loading, error, data } = useQuery(GET_COMPANIES_BRANCHES)
	const [
		newUser,
		{ data: newUserData, loading: newUserLoging, error: newUserError },
	] = useMutation(NEW_USER)
	const [companyError, setCompanyError] = useState<boolean>(false)
	const [branchError, setBranchError] = useState<boolean>(false)
	const [userTypeError, setUserTypeError] = useState<boolean>(false)
	const [nameError, setNameError] = useState<boolean>(false)
	const [passwordError, setPasswordError] = useState<boolean>(false)
	const [open, setOpen] = useState<boolean>(false)

	const handleChangeUserType = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setUserType(event.target.value)
		if (event.target.value !== '') {
			setUserTypeError(false)
		}
	}

	const handleChangeCompany = (event: SelectChangeEvent) => {
		setCompany(event.target.value as string)
		if (event.target.value !== '') {
			setCompanyError(false)
		}
		setUserType('')
	}

	const handleChangeBranch = (event: SelectChangeEvent) => {
		setBranch(event.target.value as string)
		if (event.target.value !== '') {
			setBranchError(false)
		}
	}

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value)
		if (event.target.value !== '') {
			setNameError(false)
		}
	}

	const handleChangePassWord = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setPassWord(event.target.value)
		if (event.target.value.length >= 16) {
			setPasswordError(false)
		}
	}

	const checkInput = () => {
		let flag = false

		if (company === '') {
			setCompanyError(true)
			flag = true
		}

		if (branch === '') {
			setBranchError(true)
			flag = true
		}

		if (userType === '') {
			setUserTypeError(true)
			flag = true
		}

		if (name === '') {
			setNameError(true)
			flag = true
		}

		if (password.length < 16) {
			setPasswordError(true)
			flag = true
		}

		return flag
	}

	const clearForm = () => {
		setCompany('')
		setBranch('')
		setUserType('riyosya')
		setName('')
		setPassWord(generatePassword(16))
		setUniqueId(uuid().slice(0, 8))
	}

	useEffect(() => {
		setBranchList(getBranches(loading, error, data, company))
		setBranch('')
	}, [company])

	// Avoid Radio Group controll error
	// Avoid Textfield name control error
	useLayoutEffect(() => {
		setUserType('riyosya')
		setName('')
	}, [])

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2, p: '1rem' }}>
				<CompanyBranch
					company={company}
					branch={branch}
					branchList={branchList}
					companies={!loading && !error ? data.allCompany : []}
					loading={loading}
					error={error}
					onChangeCompany={handleChangeCompany}
					onChangeBranch={handleChangeBranch}
					companyError={companyError}
					branchError={branchError}
				/>
				<Grid2 container spacing={1}>
					<Grid2 xs={6}>
						<FormControl
							sx={{ mt: 1, mb: 1 }}
							error={userTypeError}
						>
							<FormLabel id="capability-seelct">役職</FormLabel>
							<CapabilityRadio
								company={
									company !== ''
										? data.allCompany.find(
												(c: Company) => c.id === company
										  ).name
										: ''
								}
								userType={userType}
								handleChangeUserType={handleChangeUserType}
							/>
							{userTypeError && (
								<FormHelperText>
									選択してください。
								</FormHelperText>
							)}
						</FormControl>
					</Grid2>
				</Grid2>
				<Grid2 container spacing={1} sx={{ mb: 2 }}>
					<Grid2 xs={3}>
						<FormControl
							fullWidth
							sx={{ mt: 1, mb: 1 }}
							variant="standard"
						>
							<TextField
								label="名前"
								id="name-input"
								value={name}
								variant="standard"
								onChange={handleChangeName}
								error={nameError}
								helperText={nameError && '入力されていません'}
							/>
						</FormControl>
					</Grid2>
				</Grid2>
				<Grid2 container spacing={2}>
					<Grid2 xs={3}>
						<FormControl
							fullWidth
							sx={{ mt: 1, mb: 1 }}
							variant="standard"
						>
							<InputLabel htmlFor="standard-adornment-amount">
								ユーザーID
							</InputLabel>
							<Input
								id="standard-adornment-amount"
								disabled
								startAdornment={
									<InputAdornment position="start">
										user-{uniqueId}
									</InputAdornment>
								}
							/>
						</FormControl>
					</Grid2>
					<Grid2 xs={3}>
						<FormControl
							fullWidth
							sx={{ mt: 1, mb: 1 }}
							variant="standard"
						>
							<TextField
								label="パスワード"
								id="standard-size-normal"
								value={password}
								variant="standard"
								onChange={handleChangePassWord}
								error={passwordError}
								helperText={
									passwordError &&
									'16文字以上入力してください'
								}
							/>
						</FormControl>
					</Grid2>
				</Grid2>
				<FormControl
					sx={{
						m: 1,
						mt: 2,
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<Button
						sx={{ width: '100px' }}
						onClick={(e) => {
							e.preventDefault()
							const input = {
								loginId: 'user-' + uniqueId,
								password: password,
								capability: userType,
								name: name,
								branchId: branch,
							}
							const check = checkInput()
							if (!check) {
								newUser({
									variables: {
										input: input,
									},
									onCompleted: () => setOpen(true),
								})
								clearForm()
							}
						}}
						variant="contained"
					>
						追加
					</Button>
				</FormControl>
				<Notify
					label="ユーザーを追加しました。"
					open={open}
					onClose={() => setOpen(false)}
				/>
			</Paper>
		</Box>
	)
}

export default UserNew
