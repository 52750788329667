import {
	List,
	ListItem,
	ListItemText,
	Popover,
	Typography,
} from '@mui/material'
import File from '../../types/File'

const ListPopover = ({
	files,
	open,
	anchorEl,
	onClose,
}: {
	files: File[]
	open: boolean
	anchorEl: HTMLElement | null
	onClose: () => void
}) => {
	return (
		<Popover
			id="mouse-over-popover"
			open={open}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			onClose={onClose}
			disableRestoreFocus
		>
			{files.length > 0 ? (
				<List dense sx={{ maxHeight: '300px', overflowY: 'auto' }}>
					{files.map((file: File) => {
						return (
							<ListItem key={file.id}>
								<ListItemText primary={file.filename} />
							</ListItem>
						)
					})}
				</List>
			) : (
				<Typography sx={{ fontSize: 0.875, p: 1 }}>
					ダウンロードしたファイルはありません。
				</Typography>
			)}
		</Popover>
	)
}

export default ListPopover
