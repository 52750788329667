import {
	Box,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	FormControl,
	InputLabel,
	Input,
} from '@mui/material'
import { useState } from 'react'
import _ from 'lodash'
import { Delete, Edit } from '@mui/icons-material'
import { red } from '@mui/material/colors'
import Company from '../../types/Company'
import Branch from '../../types/Branch'
import {
	ApolloQueryResult,
	OperationVariables,
	useMutation,
} from '@apollo/client'
import {
	CHANGE_BRANCH,
	CHANGE_COMPANY,
	DELETE_BRANCH,
	DELETE_COMPANY,
} from '../gql/Mutation'
import Notify from '../notify/Notify'

const SettingTableList = ({
	type,
	defaultData,
	refetch,
}: {
	type: string
	defaultData: Company[] | Branch[]
	refetch: (
		variables?: Partial<OperationVariables> | undefined
	) => Promise<ApolloQueryResult<any>>
}) => {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(5)
	const [openEdit, setOpenEdit] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [currentData, setCurrentData] = useState<Company | Branch>({})
	const [value, setValue] = useState<string>('')
	const [openEditNotify, setOpenEditNotify] = useState<boolean>(false)
	const [openDeleteNotify, setOpenDeleteNotify] = useState<boolean>(false)
	const [openDeleteMissingNotify, setOpenDeleteMissingNotify] =
		useState<boolean>(false)
	const [
		changeCompany,
		{
			loading: changeCompanyLoading,
			error: changeCompanyError,
			data: changeCompanyData,
		},
	] = useMutation(CHANGE_COMPANY)
	const [
		deleteCompany,
		{
			loading: deleteCompanyLoading,
			error: deleteCompanyError,
			data: deleteCompanyData,
		},
	] = useMutation(DELETE_COMPANY)
	const [
		changeBranch,
		{
			loading: changeBranchLoading,
			error: changeBranchError,
			data: changeBranchData,
		},
	] = useMutation(CHANGE_BRANCH)
	const [
		deleteBranch,
		{
			loading: deleteBranchLoading,
			error: deleteBranchError,
			data: deleteBranchData,
		},
	] = useMutation(DELETE_BRANCH)

	const typeLabel = type === 'company' ? '会社名' : '支店名'

	const handleClickOpenEdit = (id: string) => {
		setOpenEdit(true)
		const target = Array.from(defaultData).find(
			(d: Company | Branch) => d.id === id
		) as Company | Branch
		setCurrentData(target)
		setValue(target.name as string)
	}

	const handleCloseEdit = () => {
		setOpenEdit(false)
		setCurrentData({})
	}

	const handleClickOpenDelete = (id: string) => {
		setOpenDelete(true)
		const target = Array.from(defaultData).find(
			(d: Company | Branch) => d.id === id
		) as Company | Branch
		setCurrentData(target)
	}

	const handleCloseDelete = () => {
		setOpenDelete(false)
		setCurrentData({})
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - defaultData.length)
			: 0

	return (
		<Box sx={{ width: '100%', maxWidth: '400px', mt: '1rem' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell align="center">
									{typeLabel}
								</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{defaultData
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map((row, index) => {
									const labelId = `setting-table-${row.id}`

									return (
										<TableRow
											hover
											tabIndex={-1}
											key={row.id}
										>
											<TableCell
												id={labelId}
												scope="row"
												align="center"
											>
												{row.name}
											</TableCell>
											{type === 'company' &&
											index === 0 ? (
												<>
													<TableCell padding="checkbox"></TableCell>
													<TableCell padding="checkbox"></TableCell>
												</>
											) : (
												<>
													<TableCell padding="checkbox">
														<IconButton
															edge="end"
															aria-label="edit"
															onClick={(e) =>
																handleClickOpenEdit(
																	row.id as string
																)
															}
														>
															<Edit color="primary" />
														</IconButton>
													</TableCell>
													<TableCell padding="checkbox">
														<IconButton
															edge="end"
															aria-label="remove"
															onClick={(e) =>
																handleClickOpenDelete(
																	row.id as string
																)
															}
														>
															<Delete
																sx={{
																	color: red[700],
																}}
															/>
														</IconButton>
													</TableCell>
												</>
											)}
										</TableRow>
									)
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				{defaultData.length > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 15]}
						component="div"
						count={defaultData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="表示数"
					/>
				)}
				<Dialog open={openEdit} onClose={handleCloseEdit}>
					<DialogTitle>編集</DialogTitle>
					<DialogContent>
						<DialogContentText sx={{ mb: 2 }}>
							編集後保存ボタンを押してください。
						</DialogContentText>
						<FormControl fullWidth variant="standard">
							<InputLabel htmlFor="standard-adornment-amount">
								{typeLabel}
							</InputLabel>
							<Input
								value={value}
								onChange={(e) => setValue(e.target.value)}
							/>
						</FormControl>
					</DialogContent>
					<DialogActions sx={{ p: '1rem' }}>
						<Button onClick={handleCloseEdit}>キャンセル</Button>
						<Button
							onClick={(e) => {
								e.preventDefault()
								const options = {
									variables: {
										id: currentData.id,
										name: value,
									},
									onCompleted: () => {
										refetch()
										setOpenEditNotify(true)
									},
								}
								type === 'company'
									? changeCompany(options)
									: changeBranch(options)
								handleCloseEdit()
							}}
							variant="contained"
						>
							保存
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={openDelete} onClose={handleCloseDelete}>
					<DialogTitle>削除</DialogTitle>
					<DialogContent>
						<DialogContentText>
							本当に削除してもよろしですか
						</DialogContentText>
					</DialogContent>
					<DialogActions sx={{ p: '1rem' }}>
						<Button onClick={handleCloseDelete}>キャンセル</Button>
						<Button
							color="error"
							onClick={(e) => {
								e.preventDefault()
								const options = {
									variables: {
										id: currentData.id,
									},
									onCompleted: (res: any) => {
										refetch()
										if (
											type === 'company' &&
											res.deleteCompany === true
										) {
											setOpenDeleteNotify(true)
										} else if (
											type === 'company' &&
											res.deleteCompany === false
										) {
											setOpenDeleteMissingNotify(true)
										} else if (
											type === 'branch' &&
											res.deleteBranch === true
										) {
											setOpenDeleteNotify(true)
										} else if (
											type === 'branch' &&
											res.deleteBranch === false
										) {
											setOpenDeleteMissingNotify(true)
										}
									},
								}
								type === 'company'
									? deleteCompany(options)
									: deleteBranch(options)
								handleCloseDelete()
							}}
							variant="contained"
						>
							削除
						</Button>
					</DialogActions>
				</Dialog>
				<Notify
					label={`${typeLabel}を変更しました。`}
					open={openEditNotify}
					onClose={() => setOpenEditNotify(false)}
				/>
				<Notify
					label={`${typeLabel}を削除しました。`}
					open={openDeleteNotify}
					onClose={() => setOpenDeleteNotify(false)}
				/>
				<Notify
					label={`紐づいているユーザーがいるため削除に失敗しました。`}
					open={openDeleteMissingNotify}
					onClose={() => setOpenDeleteMissingNotify(false)}
				/>
			</Paper>
		</Box>
	)
}

export default SettingTableList
